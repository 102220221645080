import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import "yup-phone";
import { useFormik } from "formik";
import { useAppDispatch } from "../../app/hooks";
import {
  getSettingSMS,
  studentActions,
} from "../../state/student/studentSlice";
import { fromPhoneNumberToString, toPhoneNumber } from "../../utils/string";
import useOutsideClick from "../../hooks/useOutsideClick";
import tickAnimate from "../../assets/img/tick-animation.gif";
import { useSelector } from "react-redux";
import { getSettingSMSSelector } from "../../state/student/studentSelector";

type notificationEntity = {
  enabled: string;
  sms_number?: string;
};
type NotificationProps = {
  notificationData: notificationEntity | undefined;
};
type notificationValue = {
  sms_number?: string;
  sms_enabled: boolean;
  sms_consent: string;
};
const notificationValidateSchema = Yup.object({
  sms_number: Yup.string().phone(
    "US",
    false,
    "SMS Number is required and must be a valid US phone number in the format nnnnnnnnnn"
  ),
  sms_consent: Yup.string().required("Please provide consent"),
});
const EditNotificationModal: React.FC<NotificationProps> = ({
  notificationData,
}) => {
  const settingSMSState = useSelector(getSettingSMSSelector);

  const dispatch = useAppDispatch();
  const initialValues: notificationValue = {
    sms_enabled: false,
    sms_number: "",
    sms_consent: "test",
  };
  const closeRef = useRef(null);
  const [responseStatus, setResponseStatus] = useState({
    showSuccess: false,
    error: "",
    successMessage: "",
  });

  useOutsideClick(closeRef, () => {
    setInitialValues();
    setResponseStatus({
      showSuccess: false,
      error: "",
      successMessage: "",
    });
  });

  const notificationHandlers = useFormik<notificationValue>({
    initialValues,
    validationSchema: notificationValidateSchema,
    onSubmit: () => {
      dispatch(
        studentActions.postSettingSMS({
          data: {
            sms_enabled: notificationHandlers.values.sms_enabled ? "1" : "0",
            sms_number: fromPhoneNumberToString(
              notificationHandlers.values.sms_number?.toString()
            ),
            sms_consent: "test",
          },
          onComplete: (error, data) => {
            if (data?.status) {
              dispatch(getSettingSMS());
              setResponseStatus({
                showSuccess: true,
                error: "",
                successMessage: data.message,
              });
              return;
            }
            setResponseStatus({
              showSuccess: false,
              error:
                data?.message ||
                "Something went wrong on our end. Please try again.",
              successMessage: "",
            });
            return;
          },
        })
      );
    },
  });

  const setInitialValues = () => {
    if (notificationData) {
      notificationHandlers.setFieldValue(
        "sms_enabled",
        notificationData.enabled === "1"
      );
      notificationHandlers.setFieldValue(
        "sms_number",
        notificationData.sms_number
      );
    }
  };

  useEffect(() => {
    setInitialValues();
  }, [notificationData]);

  return (
    <div
      className="modal fade"
      id="editNotification"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" ref={closeRef}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          {responseStatus.error && (
            <label className="errorCard">{responseStatus.error}</label>
          )}
          <div className="modal-body">
            {responseStatus.showSuccess ? (
              <div className="d-flex flex-column py-5 align-items-center justify-content-center">
                <img src={tickAnimate} alt="" width={200} />
                <p className="text-center text-muted mt-4">
                  {responseStatus.successMessage}
                </p>
              </div>
            ) : (
              <div className="form-content">
                <h2 className="xl-title text-center">
                  Edit Notifications Settings
                </h2>
                <p className="lg-title text-center mb-30">
                  Manage the alerts that are sent to your phone
                </p>
                <form
                  className="max-width-350"
                  onSubmit={notificationHandlers.handleSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="smsphone">SMS Phone Number *</label>
                    <input
                      type="text"
                      id="smsphone"
                      className="form-control"
                      placeholder="SMS Phone Number"
                      name="sms_number"
                      value={toPhoneNumber(
                        notificationHandlers.values.sms_number
                      )}
                      onChange={(e) => {
                        const result = e.target.value.replace(/\D/g, "");
                        notificationHandlers.setFieldValue(
                          "sms_number",
                          result
                        );
                      }}
                      onBlur={notificationHandlers.handleBlur}
                    />
                    {notificationHandlers.touched.sms_number &&
                    notificationHandlers.errors.sms_number ? (
                      <label className="error">
                        {notificationHandlers.errors.sms_number}
                      </label>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-between">
                    <label>Notifications</label>
                    <div>
                      <input
                        type="checkbox"
                        className="switchToggle"
                        id="Notifications"
                        name="sms_enabled"
                        checked={notificationHandlers.values.sms_enabled}
                        onChange={() =>
                          notificationHandlers.setFieldValue(
                            "sms_enabled",
                            !notificationHandlers.values.sms_enabled
                          )
                        }
                      />
                      <label htmlFor="Notifications" />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w100 mt-4"
                    disabled={settingSMSState.isFetching}
                  >
                    Save Changes
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNotificationModal;
